var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": 760,
        "no-click-animation": "",
        scrollable: "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "update-mod" },
        [
          _c(
            "v-card-title",
            {
              staticClass: "mx-2",
              staticStyle: {
                "justify-content": "center",
                "flex-direction": "column",
              },
            },
            [
              _c(
                "v-layout",
                {
                  attrs: { "justify-center": "", "align-center": "", wrap: "" },
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "font-weight-bold text-center",
                      staticStyle: { "font-size": "18px" },
                      attrs: { sm12: "" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("msg_box[1]")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.canSkip
                ? _c(
                    "span",
                    { staticClass: "close-icon" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            depressed: "",
                            text: "",
                            icon: "",
                            color: "dark lighten-2",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "py-0", class: _vm.widthClasses },
            [_vm.dialog ? _c("UpdateForm", { ref: "billingform" }) : _vm._e()],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "mx-3 my-3" },
            [
              _c(
                "v-layout",
                {
                  attrs: { "justify-center": "", "align-center": "", wrap: "" },
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "text-center",
                      attrs: { sm10: "", md8: "" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: {
                            depressed: "",
                            small: "",
                            block: "",
                            large: "",
                            color: "primary",
                            loading: _vm.loading,
                          },
                          on: { click: _vm.updateBillingInfo },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("button.update")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }