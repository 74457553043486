var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "n-box" },
    [
      _c(
        "v-text-field",
        {
          staticClass: "input-box",
          attrs: {
            label: _vm.label,
            rules: _vm.rules,
            solo: _vm.solo,
            clearable: "",
            "hide-details": !_vm.showDetails,
            "hide-no-data": "",
          },
          model: {
            value: _vm.inputValue,
            callback: function ($$v) {
              _vm.inputValue = $$v
            },
            expression: "inputValue",
          },
        },
        [
          _c("v-select", {
            staticClass: "pa-0 mb-0 iso2 select-box",
            attrs: {
              slot: "prepend",
              items: _vm.countries,
              "item-text": "name",
              "item-value": "iso2",
              solo: _vm.solo,
              "hide-details": "",
            },
            slot: "prepend",
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "pl-2 select-item d-flex align-center" },
                      [
                        _c("span", {
                          staticClass: "na-flag d-inline-block mr-1",
                          class: item.iso2,
                        }),
                        _c("span", { staticClass: "grey--text text-caption" }, [
                          _vm._v(_vm._s("+ " + item.dialCode)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("div", { staticClass: "d-flex align-center" }, [
                      _c("span", {
                        staticClass: "na-flag mr-1 d-inline-block",
                        class: item.iso2,
                      }),
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _c("span", { staticClass: "grey--text" }, [
                        _vm._v(
                          _vm._s(
                            item.dialCode ? " (+ " + item.dialCode + ")" : ""
                          )
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.singleCountry,
              callback: function ($$v) {
                _vm.singleCountry = $$v
              },
              expression: "singleCountry",
            },
          }),
          _vm.required
            ? _c(
                "template",
                { slot: "label" },
                [_c("RequiredDot", [_vm._v(_vm._s(_vm.label))])],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }