var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "v-app",
        {
          staticClass: "main-page",
          staticStyle: { "background-color": "#f7f7f7" },
          attrs: { light: "" },
        },
        [
          _c("LeftDrawer"),
          _c("AppBar", { attrs: { emailFlag: _vm.emailFlag } }),
          _c(
            "v-main",
            { attrs: { id: "main" } },
            [
              _vm.ready
                ? [
                    _c(
                      "transition",
                      { attrs: { name: "slide", mode: "out-in" } },
                      [_c("router-view")],
                      1
                    ),
                    _vm.$p(["account.information.bind_phone"])
                      ? [
                          _c("BindPhoneConfirm", {
                            ref: "updatephone",
                            on: {
                              "hook:mounted": function ($event) {
                                _vm.$root.billingCpn = _vm.$refs.updatephone
                              },
                            },
                          }),
                        ]
                      : [
                          _c("UpdateBillingInfo", {
                            ref: "updatebillinginfo",
                            on: {
                              "hook:mounted": function ($event) {
                                _vm.$root.billingCpn =
                                  _vm.$refs.updatebillinginfo
                              },
                            },
                          }),
                        ],
                    _vm.clientId && _vm.clientId !== -1
                      ? _c("NewTicket", { ref: "openTicket" })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c(
                "v-overlay",
                {
                  attrs: {
                    absolute: "",
                    opacity: "0.1",
                    value: _vm.$store.state.topLoading,
                  },
                },
                [_c("img", { attrs: { src: _vm.per } })]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("Token", { on: { login: _vm.loginHook } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }