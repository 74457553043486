var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadReady
    ? _c(
        "div",
        { staticClass: "detail" },
        [
          _c(
            "textarea",
            _vm._b({ attrs: { id: _vm.RId } }, "textarea", _vm.$attrs, false)
          ),
          _vm._t("default"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }