var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": 500,
        "no-click-animation": "",
        scrollable: "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "mx-2" }, [
            _c(
              "div",
              {
                staticClass: "font-weight-regular",
                staticStyle: { "font-size": "24px" },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.showBtn ? _vm.$t("tip_mod[0]") : _vm.$t("tip_mod[1]")
                    ) +
                    "\n        "
                ),
              ]
            ),
            _vm.formData.canSkip
              ? _c(
                  "span",
                  { staticClass: "close-icon" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          depressed: "",
                          text: "",
                          icon: "",
                          color: "dark lighten-2",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.close()
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("close")])],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "v-card-text",
            [
              _vm.showBtn
                ? _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { xs12: "", "px-2": "" } }, [
                        _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.firstStep
                                  ? _vm.$t("tip_mod[2]")
                                  : _vm.$t("tip_mod[3]", [_vm.realPhone])
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.showBtn
                ? _c(
                    "v-form",
                    { ref: "form_old" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", md12: "", "px-2": "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "validate-on-blur": "",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.cn_phone,
                                  ],
                                  prefix: "+86",
                                  required: "",
                                  clearable: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c("RequiredDot", [
                                            _vm._v(
                                              _vm._s(_vm.$t("old_phone[0]"))
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1426756820
                                ),
                                model: {
                                  value: _vm.old_phone_number,
                                  callback: function ($$v) {
                                    _vm.old_phone_number = $$v
                                  },
                                  expression: "old_phone_number",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showBtn
                ? _c(
                    "v-tabs-items",
                    {
                      staticClass: "mt-3",
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { value: 1 } },
                        [
                          _c(
                            "v-form",
                            { ref: "form_phone" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", md12: "", "px-2": "" },
                                    },
                                    [
                                      _c("NumberInput", {
                                        attrs: {
                                          rules: _vm.phoneRules,
                                          required: "",
                                          "show-details": "",
                                          label: _vm.showBtn
                                            ? _vm.$t("user.phone_number")
                                            : _vm.$t("old_phone[1]"),
                                        },
                                        model: {
                                          value: _vm.phone_number,
                                          callback: function ($$v) {
                                            _vm.phone_number = $$v
                                          },
                                          expression: "phone_number",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: 2 } },
                        [
                          _c(
                            "v-form",
                            { ref: "form_code" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs8: "", "px-2": "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: [
                                            _vm.rules.required,
                                            _vm.codeRule,
                                          ],
                                          clearable: "",
                                          "validate-on-blur": "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.codeError = true
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("RequiredDot", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("tip_code[0]")
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1558503727
                                        ),
                                        model: {
                                          value: _vm.formData.code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "code", $$v)
                                          },
                                          expression: "formData.code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs4: "",
                                        "px-2": "",
                                        "align-self-center": "",
                                      },
                                    },
                                    [
                                      !_vm.codeLoading
                                        ? _c(
                                            "v-btn",
                                            {
                                              ref: "codeBtn",
                                              staticClass: "text-none",
                                              staticStyle: { width: "92%" },
                                              attrs: {
                                                depressed: "",
                                                loading: _vm.btnLoading,
                                                outlined: "",
                                                color: "blue darken-1",
                                                text: "",
                                              },
                                              on: { click: _vm.fetchCode },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("tip_code[1]")
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.codeLoading
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-none",
                                              staticStyle: { width: "92%" },
                                              attrs: {
                                                depressed: "",
                                                outlined: "",
                                                disabled: "",
                                                text: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(_vm.codeTime + "s") +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "mx-3 mb-3" },
            [
              _c("v-spacer"),
              _vm.showBtn && _vm.firstStep
                ? _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: { depressed: "", size: "small", color: "primary" },
                      on: { click: _vm.handleNext },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("button.next")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.showBtn || !_vm.firstStep
                ? _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: {
                        depressed: "",
                        size: "small",
                        color: "primary",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.ok },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("button.submit")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("UpdateBillingInfo", { ref: "updateBillingInfo" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }